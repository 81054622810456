// material-ui
import { Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';

import logoDark from 'assets/images/logo-dark.svg';
import logo from 'assets/images/logo.png';
import logoCosmoverse from 'assets/images/logo-cosmoverse.png';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const { profileId, role } = useSelector((state) => state.userReducer);
    const theme = useTheme();
    if (profileId === 1 && role === 0) {
        return <img src={logo} alt="Logo" width="50" />;
    } else if (profileId === 2 && role === 0) {
        return <img src={logoCosmoverse} alt="Logo" width="50" />;
    } else {
        return <img src={logo} alt="Logo" width="50" />;
    }
};

export default Logo;
