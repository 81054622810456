// assets
import { IconDashboard, IconUsers, IconCalendarTime, IconBed, IconFileInvoice, IconReportAnalytics, IconSettings } from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconUsers,
    IconCalendarTime,
    IconBed,
    IconFileInvoice,
    IconReportAnalytics,
    IconSettings
};

// ==============================|| CUSTOMER MENU ITEMS ||============================== //

const frontDeskMenuItems = [
    // {
    //     id: 'dashboard',
    //     type: 'group',
    //     children: [
    //         {
    //             id: 'default',
    //             title: 'Dashboard',
    //             type: 'item',
    //             url: '/dashboard',
    //             icon: icons.IconDashboard,
    //             breadcrumbs: false
    //         }
    //     ]
    // },
    {
        id: 'main-pages',
        type: 'group',
        children: [
            {
                id: 'patients',
                title: 'Patients',
                type: 'item',
                icon: icons.IconUsers,
                url: '/patients',
                breadcrumbs: false
            },
            {
                id: 'appointments',
                title: 'Appointments',
                type: 'collapse',
                icon: icons.IconCalendarTime,
                breadcrumbs: false,
                children: [
                    {
                        id: 'todays-appointments',
                        title: "Today's Appointments",
                        type: 'item',
                        url: '/todays-appointments',
                        breadcrumbs: false
                    },
                    {
                        id: 'all-appointments',
                        title: 'All Appointments',
                        type: 'item',
                        url: '/all-appointments',
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'ipd',
                title: 'IPD',
                type: 'collapse',
                icon: icons.IconBed,
                breadcrumbs: false,
                children: [
                    {
                        id: 'ipd-overview',
                        title: 'IPD Overview',
                        type: 'item',
                        url: '/ipd-overview',
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'billing',
                title: 'Billing',
                type: 'collapse',
                icon: icons.IconFileInvoice,
                breadcrumbs: false,
                children: [
                    {
                        id: 'opdReceipts',
                        title: 'OPD Receipts',
                        type: 'item',
                        url: '/opd-receipts',
                        breadcrumbs: false
                    }
                ]
            }
        ]
    }
    // {
    //     id: 'setting-pages',
    //     type: 'group',
    //     children: [
    //         {
    //             id: 'reports',
    //             title: 'Reports',
    //             type: 'collapse',
    //             url: '/sample-page',
    //             icon: icons.IconReportAnalytics,
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'settings',
    //             title: 'Settings',
    //             type: 'collapse',
    //             url: '/sample-page',
    //             icon: icons.IconSettings,
    //             breadcrumbs: false
    //         }
    //     ]
    // }
];

export default frontDeskMenuItems;
